import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseForm, cleanNotAlphaNumericUnderScore, FieldDto } from '@shared/classes';
import { ViewModeService } from '@shared/services/view-mode.service';

@Component({
  selector: 'app-field-item-form-type',
  templateUrl: './field-item-form.component.html',
  styleUrls: ['./field-item-form.component.scss'],
})
export class FieldItemTypeFormComponent extends BaseForm<FieldDto> implements OnInit {
  constructor(public viewModeService: ViewModeService) {
    super(viewModeService);
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat({
          ...this.formGroup.getRawValue(),
          key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value),
        })
      : this.getChangedFormValues({
          ...this.formGroup.getRawValue(),
          key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value),
        }).updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;

    this.formGroup?.patchValue({
      key: cleanNotAlphaNumericUnderScore(this.formGroup?.controls?.name?.value),
    });

    if (data?.code) {
      this.formGroup?.controls?.type.disable();
    }
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      // label: new FormControl(null, Validators.required),
      name: new FormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z_$][a-zA-Z0-9_\s$]*$/)]),
      key: new FormControl(null),
      type: new FormControl(null, Validators.required),
      options: new FormControl(null),
      fieldCategory: new FormControl('TEMPLATE'),
    });
  }
}
