<app-input-view-switch
  [showLabelInViewMode]="showLabelInViewMode"
  [mode]="viewMode"
  [data]="data || control?.value"
  [dataType]="dataType"
  [label]="label"
>
  <div class="grid gap-4">
    @for (item of groupItems; track item; let i = $index) {
      <div class="field-radiobutton">
        <p-radioButton [name]="name" [value]="item.value" [formControl]="fControl" [inputId]="name + '_' + i">
        </p-radioButton>
        <label [for]="name + '_' + i"
          >{{ item.label }}
          @if (isControlRequired) {
            <span class="text-red-500"> *</span>
          }
        </label>
      </div>
    }
  </div>
</app-input-view-switch>
