<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        label="Name"
        [placeholder]="'Field Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-dynamic-field-type-selector
        [control]="formGroup?.controls?.type"
        [viewMode]="fieldViewMode"
        [appendTo]="'body'"
        label="Type"
        [placeholder]="'Search Types'"
      >
      </app-dynamic-field-type-selector>
    </div>
    <app-dynamic-field-type-options
      [control]="formGroup?.controls?.options"
      [viewMode]="fieldViewMode"
      label="Options"
      [placeholder]="'Options'"
      [fieldType]="formGroup?.controls?.type?.value"
      class="formgrid grid p-fluid w-full m-auto"
    ></app-dynamic-field-type-options>
  </div>

  <div class="flex flex-row-reverse gap-2">
    @if (fieldViewMode != 'view' && showSaveAndClose) {
      <app-button [action]="submitButtonAction"></app-button>
    }
    @if (showSaveButton) {
      <app-button [action]="submitSaveButtonAction"></app-button>
    }
  </div>
</form>
