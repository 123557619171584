<app-base-page
  [breadCrumb]="breadCrumb"
  [pageActions]="pageActions"
  [pageActionsCommandData]="pageActionsCommandData"
  [listType]="'list'"
  [cardContainer]="false"
>
  <ng-container page-notice>
    <div class="flex gap-2 align-self-center mb-2 w-12">
      <app-badge-item
        [fullWidth]="true"
        [text]="'Importer Still In BETA'"
        [displayText]="true"
        [badgeType]="'outline'"
        [status]="'yellow'"
        style="width: 100%"
      >
      </app-badge-item>
    </div>
  </ng-container>
  <ng-container page-header>
    <div class="flex gap-2">
      <p-fileUpload
        mode="basic"
        name="demo[]"
        (onSelect)="onExcelFileSelect($event)"
        (onUpload)="onExcelFileSelect($event)"
        chooseLabel="Browse CSV"
        chooseIcon="pi pi-upload"
        [accept]="'.csv'"
      ></p-fileUpload>
      <app-button [action]="downloadTemplateAction"></app-button>
    </div>
  </ng-container>
  <div class="flex w-full relative" [style]="{ height: '82vh' }">
    <div
      class="text-black text"
      id="luckysheet"
      style="margin: 0px; padding: 0px; width: 100%; height: 100%; left: 0px; top: 0px; color: black"
    ></div>
  </div>
  <!-- <div class="flex flex-row-reverse gap-2">
        <app-button [action]="submitButtonAction"></app-button>
    </div> -->
</app-base-page>
<p-dialog
  header="Importer Mode"
  [(visible)]="isEditModeModalVisible"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <div class="grid p-fluid">
    <div class="col-12">
      <p>
        Choose if the importer should work in <strong>Edit Mode</strong> to update existing items using the code column
      </p>
    </div>
    <div class="col-12">
      <!-- <app-switch-input [label]="'Edit Mode'" [name]="'editMode'"
            [control]="isEditModeFormControl" [viewMode]="'edit'">
        </app-switch-input> -->
      <app-radio-group
        [name]="'radio'"
        [control]="isEditModeFormControl"
        [groupItems]="[
          { label: 'Create Mode', value: false },
          { label: 'Edit Mode', value: true },
        ]"
      >
      </app-radio-group>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="closeDialog()" label="Confirm" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
